import React, { FC, useEffect, useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';

import { ContentfulMovieConnection } from '../model/generated/graphql';
import { Section } from '../modules/movies/components/Section';
import { motion } from 'framer-motion';

type MoviesPageProps = {
    data: {
        allContentfulMovie: ContentfulMovieConnection;
    };
};

const MoviesPage: FC<MoviesPageProps & PageProps> = ({ data, location }) => {
    const movies = data.allContentfulMovie.edges;

    const [{ page, direction }, setPage] = useState({ page: 1, direction: 0 });
    const [event, setEvent] = useState(undefined);

    const [activeMovie, setActiveMovie] = useState(null);

    const activeMovieRef = useRef();

    const paginate = (newDirection: number, e) => {
        setPage({ page: page + newDirection, direction: newDirection });
        setEvent(e);
    };

    const transition = {
        duration: 2,
        ease: [0.43, 0.13, 0.23, 0.96],
        delay: 0.5,
    };
    useEffect(() => {
        setActiveMovie(movies[page]);
    }, [movies, page]);

    return (
        <motion.div className="thumbnails" initial="initial" animate="enter" exit="exit">
            <nav style={{ position: 'absolute', bottom: 100, left: 100, zIndex: 1 }}>
                <button onClick={(e) => paginate(-1, e)}>prev</button>
                <button onClick={(e) => paginate(1, e)}>next</button>
            </nav>
            {activeMovie && (
                <motion.div
                    initial={{ y: 0 }}
                    animate={{ y: 0, transition }}
                    exit={{ y: 100, transition }}
                    key={activeMovie.node.id}
                >
                    <Section movie={activeMovie.node} />
                </motion.div>
            )}
        </motion.div>
    );
};

export default MoviesPage;

export const pageQuery = graphql`
    query MoviesQuery($node_locale: String) {
        allContentfulMovie(filter: { node_locale: { eq: $node_locale } }) {
            edges {
                node {
                    id
                    title
                    slug
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                    cover {
                        file {
                            fileName
                        }
                        gatsbyImageData(width: 1920, height: 1080, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
        }
    }
`;
//
// export const pageQuery = graphql`
//     query HomeQuery {
//         allContentfulBlogPost(sort: { fields: [publishDate], order: DESC } filter:{node_locale:{eq:"en-US"}}) {
//             edges {
//                 node {
//                     title
//                     slug
//                     publishDate(formatString: "MMMM Do, YYYY")
//                     tags
//                     heroImage {
//                         fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
//                             ...GatsbyContentfulFluid_tracedSVG
//                         }
//                     }
//                     description {
//                         childMarkdownRemark {
//                             html
//                         }
//                     }
//                 }
//             }
//         }
//         allContentfulPerson(
//             filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
//         ) {
//             edges {
//                 node {
//                     name
//                     shortBio {
//                         shortBio
//                     }
//                     title
//                     heroImage: image {
//                         fluid(
//                             maxWidth: 1180
//                             maxHeight: 480
//                             resizingBehavior: PAD
//                             background: "rgb:000000"
//                         ) {
//                             ...GatsbyContentfulFluid_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `
